import { Component, output, input } from '@angular/core';
import { NavLink } from '@welma-education/common';
import { RouterModule } from '@angular/router';
import { HasPermissionDirective } from '@welma-education/auth0';

@Component({
  selector: 'lgs-navigation',
  templateUrl: './navigation.component.html',
  imports: [RouterModule, HasPermissionDirective],
})
export class NavigationComponent {
  menuItemClick = output<MouseEvent>();
  readonly links = input<
    (NavLink & {
      permission: string;
      svgPathData: string;
    })[]
  >([]);
}
