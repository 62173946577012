import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { IconModule, icons } from '@welma-education/ui';
import { routes } from './routes';
import { API_URL } from '@welma-education/common';
import { environment, provideLgsConfiguration } from './environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideHttpClient(withInterceptors([authHttpInterceptorFn])),
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
    {
      provide: API_URL,
      useValue: environment.apiURL,
    },
    importProvidersFrom(IconModule.register(icons)),
    provideAuth0({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: window.location.origin,
      },
      httpInterceptor: {
        allowedList: [`${environment.apiURL}/*`, `/api/*`],
      },
    }),
    provideLgsConfiguration(),
  ],
};
