<nav class="space-y-1 px-2 py-4">
  @for (link of links; track link) {
    <a
      (click)="menuItemClick.emit($event)"
      [routerLink]="link.to"
      routerLinkActive=""
      #rla="routerLinkActive"
      *lgsHasPermission="link.permission"
      [routerLinkActiveOptions]="{ exact: link.to === '/' }"
      [class]="rla.isActive ? 'bg-primary-700 text-white' : 'text-primary-100'"
      class="group flex items-center rounded-md px-2 py-2 text-base font-medium transition hover:bg-primary-700 hover:text-white focus:bg-primary-700 focus:text-white focus:outline-none lg:flex-col lg:text-xs"
    >
      @if (link.svgPathData) {
        <svg
          [class]="rla.isActive ? 'text-white' : 'text-primary-300'"
          class="mr-3 size-6 transition group-hover:text-white group-focus:text-white lg:mr-0"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            [attr.d]="link.svgPathData"
          />
        </svg>
      }
      <span class="lg:mt-2"> {{ link.label }} </span>
    </a>
  }
</nav>
