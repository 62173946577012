import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { sidebarShellAnimations } from './sidebar-shell.animations';
import { DrawerModule, ProfileDropdownComponent } from '@welma-education/ui';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NavigationComponent } from './components/navigation/navigation.component';

import { NavLink } from '@welma-education/common';
import { AsyncPipe } from '@angular/common';

const navLinks: (NavLink & { permission: string; svgPathData: string })[] = [
  {
    to: '/',
    label: 'Accueil',
    permission: '',
    svgPathData:
      'M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6',
  },
  {
    to: '/offer',
    label: 'Formations',
    permission: 'read:offers',
    svgPathData: 'M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z',
  },
  {
    to: '/students',
    label: 'Étudiants',
    permission: 'enroll:students',
    svgPathData: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7',
  },
  {
    to: '/my-faculty/students',
    label: 'Étudiants',
    permission: 'read:my-faculty-students',
    svgPathData: 'M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7',
  },
  {
    to: '/my-faculty/results',
    label: 'Résultats',
    permission: 'read:my-faculty-results',
    svgPathData:
      'M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z',
  },
  {
    to: '/my-faculty/juries',
    label: 'Jurys',
    permission: 'read:my-faculty-results',
    svgPathData:
      'M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z',
  },
  {
    to: '/my-faculty/stats',
    label: 'Statistiques',
    permission: 'read:my-faculty-students',
    svgPathData:
      'M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z',
  },
  {
    to: '/teachers',
    label: 'Enseignants',
    permission: 'write:teachers',
    svgPathData:
      'M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z',
  },
  {
    to: '/juries',
    label: 'Jurys',
    permission: 'write:jurys',
    svgPathData:
      'M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z',
  },
  {
    to: '/grades',
    label: 'Notes',
    permission: 'write:grades',
    svgPathData:
      'M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z',
  },
  {
    to: '/results',
    label: 'Résultats',
    permission: 'read:results',
    svgPathData:
      'M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z',
  },
  {
    to: '/stats',
    label: 'Statistiques',
    svgPathData:
      'M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z',
    permission: 'read:stats',
  },
  {
    to: '/accounting',
    label: 'Comptabilité',
    svgPathData:
      'M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z',
    permission: 'feature:accounting',
  },
  {
    to: '/settings',
    label: 'Paramètres',
    svgPathData:
      'M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4',
    permission: 'write:settings',
  },
  {
    to: '/e-learning',
    label: 'E-learning',
    svgPathData:
      'M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25',
    permission: 'use:e-learning',
  },
  {
    to: '/accepted-candidates',
    label: 'Acceptés',
    svgPathData:
      'M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z',
    permission: 'read:accepted-candidates',
  },
  {
    to: '/candidature',
    label: 'Candidature',
    svgPathData:
      'M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z',
    permission: 'read:candidates',
  },
  {
    to: '/communication',
    label: 'Communication',
    svgPathData:
      'M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z',
    permission: 'write:news',
  },
];

@Component({
  selector: 'lgs-sidebar-layout',
  templateUrl: './sidebar-shell.component.html',
  animations: [
    sidebarShellAnimations.ngIf,
    sidebarShellAnimations.showHideOpacity,
    sidebarShellAnimations.showHideTranslate,
  ],
  host: {
    class: 'h-screen flex flex-col',
  },
  imports: [
    RouterModule,
    CdkScrollableModule,
    DrawerModule,
    NavigationComponent,
    ProfileDropdownComponent,
    AsyncPipe,
  ],
})
export class SidebarShellComponent {
  private auth = inject(AuthService);

  links = navLinks;

  user$: Observable<{ picture?: string; name?: string } | null | undefined>;

  constructor() {
    this.user$ = this.auth.user$;
  }

  logout(): void {
    this.auth.logout({ logoutParams: { returnTo: window.location.origin } }).subscribe();
  }
}
